<template>
  <h1 @click = 'getRandomMsg'>{{current}}</h1>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  },
  data() {
    return {
      msgs: ["Coming soon", "You're early...", "Just a moment", "Patience is a virtue "],
      current: "P R O X I M I T I"
    }
  },
  methods: {
    getRandomMsg() {
      this.current = this.msgs[Math.floor(Math.random() * this.msgs.length)];
    }
  },

    
  }
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
body{
  background: linear-gradient(270deg, #391c5f, #c90dfe, #fe0d8e);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    -o-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
    

   

}
#app {
  
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
  margin-top: 60px;
  font-size: 3em;
  font-weight: 0.5px;
  background: transparent;
  
}



@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

h1{

  font-size: 8vw;
   
  padding-top:10vw;
  
  border-radius:50px;
  cursor: pointer;
  

}

h2{
  font-size: 4vw;
  padding:50px;
  margin:10%;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
h2:hover{
  border:solid 1px transparent;
  background:whitesmoke;
  color:black;
  padding:100px;
  border-radius:50px;
  box-shadow: 0 50px 300px rgba(255, 255, 255, 0.4);
}
</style>
